<template>
  <div class="AppInfoList-wrapper">
    <list
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="appId" v-model="searchParams.appId" placeholder="请输入appid" />
        <v-input label="名称" v-model="searchParams.name" placeholder="请输入名称" />
        <v-select label="类型" v-model="searchParams.appType" :options="appTypeOps" />
        <v-input label="操作人" v-model="searchParams.updateUser" placeholder="请输入操作人" />
        <v-datepicker label="最后操作时间" :maxDate="maxDate" type="rangedate" :startTime.sync="searchParams.updateTimeStart" :endTime.sync="searchParams.updateTimeEnd" />
      </template>
      <template #operateSlot="scope">
        <v-button text="基础配置" type="text" permission="update" @click="edit(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL } from './api'
import { appTypeMap, setAppTypeOps } from './map'

export default {
  name: 'AppInfoList',
  data () {
    return {
      searchUrl: getListURL,
      appTypeOps: setAppTypeOps(1),
      maxDate: new Date(),
      searchParams: {
        appId: '',
        updateUser: '',
        appType: undefined,
        updateUser: '',
        updateTimeStart: '',
        updateTimeEnd: ''
      },
      headers: [
        {
          prop: 'name',
          label: '名称'
        },
        {
          prop: 'appId',
          label: 'appId'
        },
        {
          prop: 'appTypeText',
          label: '类型',
          formatter: row => appTypeMap[row.appType]
        },
        {
          prop: 'updateUser',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    }
  },
  methods: {
    create () {
      if (this.visible) {
        this.$router.push({
          name: 'miniProgramInfoForm'
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'miniProgramInfoForm',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    }
  }
}
</script>
