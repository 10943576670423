import { mapHelper } from 'common/utils'

// APP类型
const appType = [
  {
    text: '微信小程序',
    value: 2
  },
  {
    text: '支付宝小程序',
    value: 1
  },
  {
    text: '微信公众号',
    value: 3
  }
]

const {
  map: appTypeMap,
  setOps: setAppTypeOps
} = mapHelper.setMap(appType)

// 状态
const appStatus = [
    {
        text: '关闭',
        value: 0
    },
    {
        text: '正常',
        value: 1
    }
]
const {
    map: appStatusMap,
    setOps: setAppStatusOps
} = mapHelper.setMap(appStatus)

export {
  appTypeMap,
  setAppTypeOps,
  setAppStatusOps,
  appStatusMap,
}
