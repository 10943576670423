// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}SysMicroAppInfo/getMicroAppInfoListByPage`
// 关联公众号列表
const getOfficialAccountListURL = `${API_CONFIG.butlerBaseURL}SysMicroAppInfo/getOfficialAccount`
// 新增 & 编辑
const updateURL = `${API_CONFIG.butlerBaseURL}SysMicroAppInfo/addSysMicroAppInfo`
// 获取详情
const queryDetailURL = `${API_CONFIG.butlerBaseURL}SysMicroAppInfo/getMicroAppInfoById`

export {
  getListURL,
  getOfficialAccountListURL,
  updateURL,
  queryDetailURL
}
